import { Component } from 'react';
import './App.css';

class App extends Component {
  render() {
    return(
      <div className="content">
        <h1>Hello<span className="dot">.</span></h1>
        <a href="mailto:predyy@protonmail.com">predyy@protonmail.com</a>
      </div>
    )
  }
}

export default App;
